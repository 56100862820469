import { Amplify } from 'aws-amplify';
import { client } from '@/services/client';

interface UserPoolConfig {
  userPoolId: string;
  clientId: string;
}

const configStorageKey = 'UserPoolConfig';

let config: UserPoolConfig | null = null;

function configureAmplify(userPoolId: string, userPoolClientId: string) {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId,
        userPoolClientId,
      },
    },
  });
}

export function configureAmplifyInitial() {
  const storageConfig = localStorage.getItem('UserPoolConfig');
  if (storageConfig) {
    const config = JSON.parse(storageConfig) as UserPoolConfig;
    configureAmplify(config.userPoolId, config.clientId);
  } else {
    configureAmplify('dummy', 'dummy');
  }
}

async function getUserPoolConfig(): Promise<UserPoolConfig | null> {
  try {
    return client.publicGet('/api/tenant-config');
  } catch (error) {
    console.error('Failed to get tenant config', error);
    return null;
  }
}

export async function configureAmplifyFromTenantConfig() {
  if (!config) {
    config = await getUserPoolConfig();
  }

  if (config) {
    configureAmplify(config.userPoolId, config.clientId);
    localStorage.setItem('UserPoolConfig', JSON.stringify(config));
  }
}
