<script setup lang="ts">
import { signOut } from 'aws-amplify/auth';
import { useRouter } from 'vue-router';
import { userStore } from '@/store/user';

const emit = defineEmits(['toggle-sidebar', 'open-customer-overlay']);

const router = useRouter();

async function logout() {
  await signOut();
  userStore.loggedIn = false;
  userStore.user = null;
  router.push({ name: 'Login' });
}

const props = defineProps<{
  sidebarVisible: boolean
}>();

</script>

<template>
  <div class="layout-header">
    <div class="flex h-full">
      <div class="flex items-center justify-between w-[20rem] logo-container p-2">
        <img class="layout-header-logo" src="/src/assets/header_logo.svg" alt="Intellivend Logo">

        <i-button
          data-test="toggle-sidebar"
          severity="transparent"
          rounded
          :icon="props.sidebarVisible ? fasChevronLeft : fasChevronRight"
          @click="emit('toggle-sidebar')"
        />
      </div>

      <div class="grow" />

      <div class="flex items-center gap-4 py-2 px-4">
        <div class="flex items-center gap-4">
          <i-button
            v-tooltip.bottom="'Kunden'"
            severity="accent"
            :icon="fasUsers"
            @click="emit('open-customer-overlay')"
          />

          <i-popover-button :icon="fasUser" severity="accent">
            <div class="flex items-center gap-2">
              <p-avatar>
                <template #icon>
                  <fa-icon :icon="fasUser" />
                </template>
              </p-avatar>
              <span v-if="userStore.user" class="font-medium">
                {{ userStore.user?.firstName }} {{ userStore.user?.lastName }}
              </span>
              <span v-else class="font-medium">
                Loading..
              </span>
            </div>
            <div class="mt-4 flex justify-end">
              <i-button outlined label="Logout" @click="logout" />
            </div>
          </i-popover-button>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.layout-header {
  height: var(--p-layout-header-height);
  background: var(--p-layout-header-background);
  color: #FFF;

  .logo-container {
    background: var(--p-layout-sidebar-background);

    .layout-header-logo {
      height: 2rem;
    }
  }
}
</style>
