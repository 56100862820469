import type { MenuItem } from 'primevue/menuitem';
import { createRouter, createWebHistory } from 'vue-router';

import authGuard from '@/router/guards/auth';
import LoginView from '@/views/LoginView.vue';

declare module 'vue-router' {
  interface RouteMeta {
    breadcrumbs?: MenuItem[];
  }
}

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      name: 'Login',
      path: '/login',
      component: LoginView,
    },
    {
      name: 'AccessDenied',
      path: '/access-denied',
      component: () => import('@/views/AccessDenied.vue'),
    },
    {
      name: 'TargetManagement',
      path: '/target-management',
      component: () => import('@/views/TargetManagement.vue'),
      meta: {
        breadcrumbs: [{ label: 'Targets' }],
      },
    },
    {
      name: 'ChannelManagement',
      path: '/channel-management',
      component: () => import('@/views/ChannelManagement.vue'),
      meta: {
        breadcrumbs: [{ label: 'Kanäle' }],
      },
    },
    {
      name: 'UserManagement',
      path: '/user-management',
      component: () => import('@/views/UserManagement.vue'),
      meta: {
        breadcrumbs: [{ label: 'Benutzer & Teams' }],
      },
    },
    {
      name: 'UserDetails',
      path: '/user-management/:id',
      component: () => import('@/views/UserDetails.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ label: 'Benutzer', to: 'UserManagement' }],
      },
    },
    {
      name: 'TeamDetails',
      path: '/team-management/:id',
      component: () => import('@/views/TeamDetails.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ label: 'Teams', to: 'UserManagement' }],
      },
    },
    {
      name: 'Dashboard',
      path: '/dashboard',
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        breadcrumbs: [{ label: 'Dashboard' }],
      },
    },
    {
      name: 'ChannelDetails',
      path: '/channel-management/:id',
      component: () => import('@/views/ChannelDetails.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ label: 'Kanäle', to: 'ChannelManagement' }],
      },
    },
    {
      name: 'CatalogManagement',
      path: '/catalog',
      component: () => import('@/views/CatalogManagement.vue'),
      meta: {
        breadcrumbs: [{ label: 'Katalog' }],
      },
    },
    {
      name: 'TargetDetails',
      path: '/target-management/:id',
      component: () => import('@/views/TargetDetails.vue'),
      props: true,
      meta: {
        breadcrumbs: [{ label: 'Targets', to: 'TargetManagement' }],
      },
    },
    {
      name: 'Administration',
      path: '/administration',
      component: () => import('@/views/AdministrationView.vue'),
      meta: {
        breadcrumbs: [{ label: 'Administration' }],
      },
    },
    {
      path: '/',
      redirect: '/dashboard',
    },
  ],
});

router.beforeEach(authGuard);

export default router;
