<script setup lang="ts">
import { I18n } from 'aws-amplify/utils';
import { Authenticator, translations, useAuthenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import { useRoute, useRouter } from 'vue-router';
import { userService } from '@/services/user';
import { userStore } from '@/store/user';
import { configureAmplifyFromTenantConfig } from '@/services/auth';

await configureAmplifyFromTenantConfig();

I18n.putVocabulariesForLanguage('de', {
  ...translations.de,
  'Reset Password': 'Passwort zurücksetzen',
  'Enter your Email': 'Geben Sie Ihre E-Mail ein',
  'Enter your Password': 'Geben Sie Ihr Passwort ein',
});
I18n.setLanguage('de');

const formFields = {
  forgotPassword: {
    username: {
      label: 'Passwort zurücksetzen',
    },
  },
};

const isLoggedIn = computed(() => userStore.loggedIn);

const router = useRouter();
const route = useRoute();
const auth = useAuthenticator();

watch([() => auth.authStatus, () => auth.user], async () => {
  if (auth.authStatus === 'authenticated' && auth.user) {
    const user = await userService.getUser(auth.user.userId);

    if (user.role.canUseCockpit) {
      userStore.user = user;
      userStore.loggedIn = true;

      if (typeof route.query.redirect === 'string') {
        router.push(route.query.redirect);
      } else {
        router.push({ name: 'TargetManagement' });
      }
    } else {
      auth.signOut();
    }
  }
}, { immediate: true });

watch(() => auth.route, async () => {
  if (auth.route === 'signIn') {
    await nextTick();
    const { username, password } = route.query;

    if (username && password) {
      const usernameInput = document.querySelector<HTMLInputElement>('input[name="username"]')!;
      usernameInput.value = username as string;

      const passwordInput = document.querySelector<HTMLInputElement>('input[name="password"]')!;
      passwordInput.value = password as string;
    }
  }
});
</script>

<template>
  <!-- Show loading indicator until navigation is done -->
  <div
    v-if="auth.authStatus === 'authenticated'"
    class="size-full flex justify-center items-center"
  >
    <div v-if="!isLoggedIn" class="w-[30rem]">
      <img src="/src/assets/intellivend_logo.svg">
      <i-progress-bar />
    </div>
  </div>

  <authenticator
    v-else
    class="size-full flex justify-center items-center"
    hide-sign-up
    :login-mechanisms="['email']"
    :form-fields="formFields"
  >
    <template #sign-in-header>
      <img
        src="/src/assets/login_header.png"
        alt="intellivend cockpit"
        width="100%"
      >
    </template>

    <template #forgot-password-header>
      <img
        src="/src/assets/login_header.png"
        alt="intellivend cockpit"
      >
    </template>

    <template #confirm-reset-password-header>
      <img
        src="/src/assets/login_header.png"
        alt="intellivend cockpit"
      >
    </template>
  </authenticator>
</template>

<style>
/* Style amplify components like our primevue components */
[data-amplify-authenticator] {
  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-form-padding:
    var(--amplify-space-medium)
    var(--amplify-space-xl)
    var(--amplify-space-xl);
  --amplify-components-button-primary-background-color: #0098ff;
  --amplify-components-button-primary-hover-background-color: #007FE6;
  --amplify-components-button-link-hover-background-color: rgba(16, 185, 129, 0.04);
  --amplify-components-flex-gap: 1.5rem;
}

/* Styles that cannot be overridden via css variables */
.amplify-input, .amplify-field__show-password {
  border: 0.1rem solid #d1d5db;
  border-radius: 0.4rem !important;
}

.amplify-field__show-password {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.amplify-field-group :not(:last-child) .amplify-input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.amplify-input:focus, .amplify-button:focus {
  border-color: #85898F;
  box-shadow: none;
}

[data-amplify-router], [data-amplify-router-content] img {
  border-radius: 0.4rem;
}

/** Negate the reset header padding as it does not behave like the sign in header */
[data-amplify-authenticator-forgotpassword] img,
[data-amplify-authenticator-confirmresetpassword] img {
  margin: -1rem -2rem 1rem;
  width: calc(100% + 4rem);
}
</style>
