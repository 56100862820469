import { definePreset } from '@primevue/themes';
import Aura from '@primevue/themes/aura';

/**
 * Preset reference at {@link https://github.com/primefaces/primevue/tree/master/packages/themes/src/presets/aura}
 */
export const intellivendPreset = definePreset(Aura, {
  primitive: {
    color: {
      success: '#00A28E',
      info: '#3186EA',
      warn: '#E28801',
      error: '#FF4C4C',
    },
  },
  semantic: {
    primary: {
      50: '#f0f9ff',
      100: '#e2f3ff',
      200: '#87cdff',
      300: '#56afef',
      400: '#2d97e1',
      500: '#0077c8',
      600: '#005fa2',
      700: '#00518a',
      800: '#004f87',
    },
    accent: {
      200: '#F4FFFF',
      300: '#D2FFFF',
      400: '#B8F3F4',
      500: '#ADE0E1',
      600: '#7FCFD1',
      700: '#54ACAE',
      800: '#008DA0',
    },
    targettype: {
      sale: '#005c9e',
      lead: '#6c009e',
      insight: '#257b18',
    },
    content: {
      borderRadius: '{border.radius.sm}',
    },
    formField: {
      borderRadius: '{border.radius.sm}',
    },
    layout: {
      header: {
        height: '4rem',
        background: '#0e3250',
      },
      sidebar: {
        background: '#001938',
        activeBackground: '#0e325073',
      },
    },
    colorScheme: {
      light: {
        formField: {
          invalidBorderColor: '{red.500}',
          disabledBackground: '{surface.100}',
        },
      },
    },
  },
  components: {
    toast: {
      root: {
        borderWidth: '0 0 0 0.28rem',
      },
      icon: {
        size: '1.5rem',
      },
      colorScheme: {
        light: {
          success: {
            color: '{color.success}',
            borderColor: '{color.success}',
            background: 'color-mix(in srgb, {color.success}, #fff 95%)',
            shadow: '0 0 0.07rem 0.07rem color-mix(in srgb, {color.success}, transparent 75%)',
          },
          info: {
            color: '{color.info}',
            borderColor: '{color.info}',
            background: 'color-mix(in srgb, {color.info}, #fff 95%)',
            shadow: '0 0 0.07rem 0.07rem color-mix(in srgb, {color.info}, transparent 75%)',
          },
          warn: {
            color: '{color.warn}',
            borderColor: '{color.warn}',
            background: 'color-mix(in srgb, {color.warn}, #fff 95%)',
            shadow: '0 0 0.07rem 0.07rem color-mix(in srgb, {color.warn}, transparent 75%)',
          },
          error: {
            color: '{color.error}',
            borderColor: '{color.error}',
            background: 'color-mix(in srgb, {color.error}, #fff 95%)',
            shadow: '0 0 0.07rem 0.07rem color-mix(in srgb, {color.error}, transparent 75%)',
          },
        },
      },
    },
    paginator: {
      navButton: {
        borderRadius: '0.25rem',
        selectedBackground: '{primary.100}',
      },
    },
    datatable: {
      paginator: {
        bottom: {
          border: {
            width: '0rem',
          },
        },
      },
    },
    progressbar: {
      borderRadius: '0',
      height: '0.5rem',
      value: {
        background: '{primary.400}',
      },
    },
    tabs: {
      tab: {
        borderWidth: '0 0 0.15rem 0',
      },
    },
    breadcrumb: {
      padding: '0',
    },
    card: {
      borderRadius: '{border.radius.sm}',
      shadow: '0 0 0 0.07rem {content.border.color}',
    },
    message: {
      content: {
        padding: '1rem 1.5rem',
        gap: '1.5rem',
      },
      text: {
        fontWeight: '400',
      },
    },
  },
});
